import TourStepsContainer from '../components/TourStepsContainer';
import TourStepsSectionsContainer from '../components/TourStepsSectionsContainer';
import TourStepsSection from '../components/TourStepsSection';

import '../style.scss';

const Cards = () => (
	<TourStepsContainer>
		<h3 style={{margin: '0 0 0.4rem 0'}}>Cards</h3>
		<p>Cards provide access to different visualisation templates.</p>
		<TourStepsSectionsContainer>
			<TourStepsSection>
				<p>
					<b>Detailed exploration</b> - Detailed exploration of individual
					WorldCereal products.
				</p>
				<p style={{marginBottom: 0}}>
					<b>Global view</b> - Global exploration of all WorldCereal products.
				</p>
			</TourStepsSection>
			<TourStepsSection isRight>
				<p>
					<b>Statistics</b> - Exploration of statistics derived from WorldCereal
					products.
				</p>
				<p style={{marginBottom: 0}}>
					<b>User products</b> - Exploration of products generated by individual
					users.
				</p>
			</TourStepsSection>
		</TourStepsSectionsContainer>
	</TourStepsContainer>
);

export default Cards;
