import {connect} from '@gisatcz/ptr-state';

import Presentation from './presentation';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
